import React from 'react';
import AnimatedSlider from '../../components/animated-slider/animated-slider.component';
import CardList from '../../components/card-list/card-list.component';
import styles from './styles.module.css';
import useComponent from './hook';

const MainPage = (props) => {
    const { header, posts } = props.content;
    const cardsData = {
        posts,
    };
    const { t } = useComponent();
    return (
        <section className={styles.mainPageContainer}>
            <AnimatedSlider />
            <h1 className="text-center text-light d-block my-5">{t(header)}</h1>
            <CardList data={cardsData} />
        </section>
    );
};

export default MainPage;
