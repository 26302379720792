import React from 'react';
import useComponent from './hook';
import PlFlag from '../../assets/images/plFlag.png';
import EnFlag from '../../assets/images/enFlag.png';
import styles from './styles.module.css';

export const langs = {
    nextLang: 'i18nextLng',
    en: 'en',
    pl: 'pl',
};

export const LanguageSelector = () => {
    const { localStorageLanguage, onChangeToEng, onChangeToPl } = useComponent(langs);
    return (
        <div className={styles.flagContainer}>
            {localStorageLanguage.includes(langs.pl)
                ? changeLanguageImage(EnFlag, langs.en, onChangeToEng)
                : changeLanguageImage(PlFlag, langs.pl, onChangeToPl)}
        </div>
    );
};

function changeLanguageImage(flag, language, onChangeLanguage) {
    return (
        <div onClick={onChangeLanguage}>
            <img className={styles.flag} src={flag} alt={language} />
        </div>
    );
}
