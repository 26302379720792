import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useComponent = () => {
    const navLinkRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        onResize(navLinkRef);
        window.addEventListener('resize', () => onResize(navLinkRef));
        return () => window.removeEventListener('resize', () => onResize(navLinkRef));
    });
    return {
        navLinkRef,
        t,
    };
};

function onResize(navLinkRef) {
    if (window.innerWidth >= 992) {
        navLinkRef.current.dataset.toggle = '';
    } else {
        navLinkRef.current.dataset.toggle = 'collapse';
    }
}

export default useComponent;
