import React from 'react';
import useComponent from './hook';

const TechnologiesArticle = ({ technologies }) => {
  const { title, content } = technologies;
  const { t } = useComponent();
  return (
    <article>
      <h2 className="text-center text-sm-left">{t(title)}</h2>
      <p>{t(content)}</p>
    </article>
  );
};

export default TechnologiesArticle;
