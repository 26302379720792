import ReactDOM from 'react-dom';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import {
    correctModalRef,
    invalidModalRef,
    modalPrivacyPolicyRef,
} from '../modals-container/modals-container.component';

const INITIAL_STATE = {
    subject: '',
    message: '',
    email: '',
    isContactAgreed: false,
    isPrivacyPolicyAgreed: false,
};

const useComponent = () => {
    const { t } = useTranslation();
    const [state, onSetState] = useState(INITIAL_STATE);
    const recaptchaRef = useRef();
    const onChangeInputDone = useCallback((event) => onChangeInput(event, state, onSetState), [state]);

    const onSubmitFormDone = useCallback(
        (event) => {
            event.preventDefault();
            onSubmitForm(state, recaptchaRef, onSetState, INITIAL_STATE);
        },
        [state],
    );
    const onPrivacyPolicyModalOpenDone = useCallback(() => onPrivacyPolicyModalOpen(), []);
    return {
        t,
        state,
        recaptchaRef,
        onChangeInputDone,
        onSubmitFormDone,
        onPrivacyPolicyModalOpenDone,
    };
};

function onChangeInput(event, state, onSetState) {
    const { name } = event.target;
    let value;
    switch (name) {
        case 'isContactAgreed':
        case 'isPrivacyPolicyAgreed':
            value = event.target.checked;
            break;
        default:
            value = event.target.value;
    }
    onSetState({ ...state, [name]: value });
}

export async function onSubmitForm(state, recaptchaRef, onSetState, INITIAL_STATE) {
    const token = await recaptchaRef.current.executeAsync();
    const data = {
        ...state,
        token: token,
    };

    fetch('/new_message', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((res) => {
        if (res.status === 200) {
            onCorrectModalOpen();
            onSetState(INITIAL_STATE);
        } else {
            onInvalidModalOpen();
        }
    });
}

export function onCorrectModalOpen() {
    $(ReactDOM.findDOMNode(correctModalRef.current)).modal();
}

export function onInvalidModalOpen() {
    $(ReactDOM.findDOMNode(invalidModalRef.current)).modal();
}

export function onPrivacyPolicyModalOpen() {
    $(ReactDOM.findDOMNode(modalPrivacyPolicyRef.current)).modal();
}

export default useComponent;
