import React from 'react';
import styles from './styles.module.css';
import useComponent from './hook';

const Card = ({ textContent, className }) => {
    const { t } = useComponent();
    return (
        <article className={`${styles.singleCard} ${className}`}>
            <p className={`p-2 ${styles.cardText}`}>{t(textContent)}</p>
        </article>
    );
};

export default Card;
