import React from 'react';
import styles from './styles.module.css';
import CardList from '../../components/card-list/card-list.component';
import useComponent from './hook';

const ProjectsPage = (props) => {
    const { header, posts } = props.content;
    const cardsData = {
        posts,
    };
    const { t } = useComponent();
    return (
        <section className={styles.projectsPageContainer}>
            <h1 className="text-center">{t(header)}</h1>
            <CardList data={cardsData} />
        </section>
    );
};

export default ProjectsPage;
