import React from 'react';
import { SiLinkedin, SiGithub, SiGitlab } from 'react-icons/si';
import styles from './styles.module.css';

const IconsBar = () => {
  return (
    <div className="display-4 w-50 mx-auto mb-5 row">
      <a
        href="https://www.linkedin.com/company/dynak-com/"
        className="col-4"
      >
        <SiLinkedin className={`${styles.fontIcon}`} />
      </a>
      <a href="https://github.com/dynak-com" className="col-4">
        <SiGithub className={`${styles.fontIcon}`} />
      </a>
      <a href="https://gitlab.dynak.com/projects" className="col-4">
        <SiGitlab className={`${styles.fontIcon}`} />
      </a>
    </div>
  );
};

export default IconsBar;
